<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="1000px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item :label="$t('packageOrder.name23')" prop="logistics_num">
                    <el-input v-model="formData.logistics_num"></el-input>
                </el-form-item>
                <el-form-item :label="$t('packageOrder.name25')" prop="description">
                    <el-input v-model="formData.description"></el-input>
                </el-form-item>
                 <el-form-item :label="$t('packageOrder.sh4')" prop="name">
                    <el-input v-model="formData.name"></el-input>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.sh5')">
                            <el-select v-model="formData.type" :placeholder="$t('userManage.name5P')" clearable style="width: 100%" filterable>
                                <el-option
                                        v-for="item in categroyList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.sh8')">
                            <el-input-number v-model="formData.total_count" :min="1"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.sh6')">
                            <el-select v-model="formData.post_store" :placeholder="$t('userManage.name5P')" clearable filterable>
                                <el-option
                                        v-for="item in postStoreList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.name20')">
                            <el-input v-model="formData.user_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.name21')">
                            <el-input v-model="formData.user_mobile"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.name8')">
                            <el-input  v-model="formData.critical_mobile"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item :label="$t('packageOrder.name7')" prop="address_extend">
                    <el-input v-model="formData.address_extend" type="textarea" :rows="2"></el-input>
                    <el-alert show-icon title="分隔符;分号不要修改" type="info" />
                </el-form-item>
                <el-form-item :label="$t('packageOrder.name9')">
                    <div style="margin-bottom:5px;display:flex;align-items: center;margin-right: 30px;">
                        <el-input  v-model="formData.specs" placeholder="长*宽*高" style="width: 260px;"></el-input><span>cm³</span>
                    </div>
                    <div style="margin-bottom:5px;display:flex;align-items: center;">
                        <el-input-number v-model="formData.weight" placeholder="重量" style="width: 260px;" controls-position="right"></el-input-number><span>kg</span>
                    </div>
                </el-form-item>
                <el-row :gutter="2">
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.name10')">
                            <el-input-number v-model="formData.freight_fee" :min="0" :precision="2" style="width: 100%;" @change="calcFee"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.name19')">
                            <el-input-number v-model="formData.goods_value" :min="0" :precision="2" style="width: 100%;"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="$t('packageOrder.name11')">
                            <el-input-number v-model="formData.insure_fee" :min="0" :precision="2" style="width: 100%;"  @change="calcFee"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item :label="$t('packageOrder.name12')">
                    <el-input v-model="formData.total_fee" :placeholder="$t('packageOrder.sh9')" :disabled="true">
                        <template #append>{{$t('packageOrder.sh9')}}</template>
                    </el-input>
                </el-form-item>
                <el-row :gutter="2">
                    <el-col :span="12">
                        <el-form-item :label="$t('packageOrder.name13')">
                            <el-select v-model="formData.pay_method" :placeholder="$t('userManage.name5P')"  style="width: 100%">
                                <el-option
                                        v-for="item in payMehodList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('packageOrder.name22')">
                            <el-input-number v-model="formData.total_fee_paid" :min="0" :precision="2" style="width: 100%;"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item :label="$t('packageOrder.name24')">
                    <el-input v-model="formData.remark"  type="textarea" :rows="2"></el-input>
                </el-form-item>

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">{{$t('btn.cancel')}}</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">{{$t('btn.confirm')}}</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {wuliuPackageorderEdit} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import {deepClone} from "@/utils/util"
    export default {
        components: {LyDialog},
        emits: ['refreshData'],
        name: "moduleOrderEdit",
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                peopleList:[],
                formData:{
                    id:'',
                    address_extend:'',
                    specs:'',
                    weight:'',
                    pay_method:'',
                    name:"",
                    type:"",
                    total_count:1,
                    post_store:"",
                    insure_fee:0,
                    freight_fee:0,
                    goods_value:0,
                    total_fee:0,
                    user_name:"",
                    user_mobile:"",
                    critical_mobile:"",
                    total_fee_paid:0,
                    logistics_num:"",
                    description:"",
                },
                categroyList:[],
                postStoreList:[],
                rules:{
                    // image: [
                    //     {required: true, message: '请上传图片',trigger: 'blur'}
                    // ],
                    // link: [
                    //     {required: true, message: '请输入链接',trigger: 'blur'}
                    // ],
                    // title: [
                    //     {required: true, message: '请输入标题',trigger: 'blur'}
                    // ]
                },
                payMehodList:[
                    {id:0,name:"未付款"},
                    {id:2,name:"微信"},
                    {id:3,name:"支付宝"},
                    {id:4,name:"钱包支付"},
                    {id:5,name:"银行卡转账"},
                ],
            }
        },
        methods:{
            calcFee(){
                this.formData.total_fee = this.formData.freight_fee + this.formData.insure_fee
            },
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
            },
            addModuleFn(item,flag,category,postStore) {
                this.loadingTitle=flag
                this.dialogVisible=true
                this.categroyList = category
                this.postStoreList = postStore
                let tempdata = deepClone(item)
                this.formData.id = tempdata.id
                this.formData.address_extend = tempdata.address_extend
                this.formData.specs = tempdata.specs
                this.formData.weight = tempdata.weight
                this.formData.pay_method = tempdata.pay_method
                this.formData.name = tempdata.name
                this.formData.type = tempdata.type
                this.formData.total_count = tempdata.total_count
                this.formData.post_store = tempdata.post_store
                this.formData.insure_fee = Number(tempdata.insure_fee)
                this.formData.freight_fee = tempdata.freight_fee
                this.formData.goods_value = Number(tempdata.goods_value)
                this.formData.total_fee = Number(tempdata.total_fee)
                this.formData.user_name = tempdata.user_name
                this.formData.critical_mobile = tempdata.critical_mobile
                this.formData.user_mobile = tempdata.user_mobile
                this.formData.total_fee_paid = Number(tempdata.total_fee_paid)
                this.formData.logistics_num = tempdata.logistics_num
                this.formData.description = tempdata.description
                this.formData.remark = tempdata.remark
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        // let newimage = ""
                        // if (this.formData.image.indexOf("://")>=0){
                        //     newimage = this.formData.image.substring(this.formData.image.indexOf("/",8))
                        // }else{
                        //     newimage = this.formData.image
                        // }
                        // this.formData.image = newimage
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.id){
                            wuliuPackageorderEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                        this.loadingSave=false
                    }
                })
            },
        }
    }
</script>
<style scoped>
    :deep(.el-alert){
        padding:2px 4px;
    }
</style>

