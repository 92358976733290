<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-position="left" label-width="auto">
                <el-form-item :label="$t('packageOrder.sh1')">
                    <el-input v-model.trim="formInline.nickname" maxlength="60"  clearable :placeholder="$t('packageOrder.sh1')" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('packageOrder.sh2')">
                    <el-input v-model.trim="formInline.logistics_num" maxlength="60"  clearable :placeholder="$t('packageOrder.sh2')" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('packageOrder.sh3')">
                    <el-input v-model.trim="formInline.package_id" maxlength="60"  clearable :placeholder="$t('packageOrder.sh3')" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('packageOrder.sh4')">
                    <el-input v-model.trim="formInline.name" maxlength="60"  clearable :placeholder="$t('packageOrder.sh4')" @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('packageOrder.sh5')">
                    <el-select v-model="formInline.type_id" :placeholder="$t('userManage.name5P')" clearable style="width: 130px" @change="search" filterable>
                        <el-option
                                v-for="item in typeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('packageOrder.sh7')">
                    <el-date-picker
                            style="width:280px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            :range-separator="$t('userManage.name7')"
                            :start-placeholder="$t('userManage.name6')"
                            :end-placeholder="$t('userManage.name8')">
                    </el-date-picker>
                </el-form-item>
                <el-button v-if="hasPermission(route.name,'Search')"  @click="search" type="primary" icon="Search">{{$t('btn.search')}}</el-button>
                <el-button  @click="handleEdit('','reset')" icon="Refresh">{{$t('btn.reset')}}</el-button>
                <!-- <el-form-item label=""><el-button  @click="exportDataFrontend" type="primary">导出</el-button></el-form-item> -->
            </el-form>
        </div>
        <el-tabs v-model="formInline.status"  @tab-click="handleStatusClick" type="card" class="statusSelectTab">
            <el-tab-pane :label="$t('packageOrder.status1')" :name="1"></el-tab-pane>
            <el-tab-pane :label="$t('packageOrder.status2')" :name="2"></el-tab-pane>
        </el-tabs>
        <ul class="order-static" ref="orderStatic">
            <li>{{$t('packageOrder.tt1')}}：{{orderstatics.totalcount_today}}</li>
<!--            <li>{{$t('packageOrder.tt2')}}：{{orderstatics.totalcount}}</li>-->
            <el-button  size="small" @click="exportData" type="primary">{{$t('btn.export')}}</el-button>
        </ul>
        <el-table :height="tableHeight - 40" border  :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="55"></el-table-column>-->
            <el-table-column type="index" width="60" align="center" :label="$t('packageOrder.name0')">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)" class="lyexportexclude"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="230" :label="$t('packageOrder.name2')" fixed="left">
                <template #default="scope">
                    <div>
                        <div>包裹ID：{{scope.row.package_id}}</div>
                        <div v-if="scope.row.status == 1">
                            <div style="margin-bottom:5px;display:flex;flex-direction: column;justify-content: start">
                                快递单号：
                                <el-input size="small" v-model="scope.row.logistics_num" placeholder="快递单号" style="width: 100%" ></el-input>
                            </div>
                            <div style="text-align: right;">
                                <el-button size="small" type="primary" @click="handleSaveLogistics(scope.row)">保存</el-button>
                            </div>
                        </div>
                        <div v-else>快递单号：{{scope.row.logistics_num}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="190" :label="$t('packageOrder.name1')">
                <template #default="scope">
                    <div>
                        <div style="display:flex;align-item:center;">头像：<el-image class="lyexportexclude" :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg" :preview-src-list="[scope.row.userinfo.avatar]" preview-teleported></el-image></div>
                        <div>昵称：{{scope.row.userinfo.nickname}}</div>
                        <div>客户姓名：{{scope.row.user_name}}</div>
                        <div>客户手机：{{scope.row.user_mobile}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="220" :label="$t('packageOrder.name3')" show-overflow-tooltip>
                <template #default="scope">
                    <div>
                        <div>物品名称：{{scope.row.name}}</div>
                        <div>物品分类：{{scope.row.typename}}</div>
                        <div>物品数量：{{scope.row.total_count}}</div>
                        <div>邮寄仓库：{{scope.row.post_storename}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="230"  :label="$t('packageOrder.name4')" show-overflow-tooltip>
                <template #default="scope">
                    <div>
                        <div>提交时间：{{scope.row.create_datetime}}</div>
                        <div>入库时间：{{scope.row.store_in_time}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="150"  :label="$t('packageOrder.name5')" prop="store_in_image">
                <template #default="scope">
                    <el-image v-for="(items,indexs) in scope.row.store_in_image"  :src="items" :preview-src-list="[items]" style="width: 30px;height: 30px;margin-right: 5px"  :key="items" preview-teleported></el-image>
                </template>
            </el-table-column>
            <el-table-column min-width="130" prop="store_user_info.name" :label="$t('packageOrder.name6')" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="180" :label="$t('packageOrder.name7')">
                <template #default="scope">
                    <div v-if="!!scope.row.address">
                        <div>{{scope.row.address.receiver + " "+ scope.row.address.mobile}}</div>
                        <div>{{scope.row.address.place}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="160" :label="$t('packageOrder.name8')" show-overflow-tooltip>
                <template #default="scope">
                    <div>
                        <div>{{scope.row.critical_contacts}}</div>
                        <div>{{scope.row.critical_mobile}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="220" prop="specs"  :label="$t('packageOrder.name9')" >
                <template #default="scope">
<!--                    <div v-if="scope.row.status != 2">-->
<!--                        <div>-->
<!--                            体积：{{scope.row.specs?caclSpec(scope.row.specs):""}}-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            重量：{{scope.row.weight?scope.row.weight+"kg":''}}-->
<!--                        </div>-->
<!--                    </div>-->
                    <div>
                        <div style="margin-bottom:5px;display:flex;align-items: center;">
                            <el-input size="small" v-model="scope.row.specs" placeholder="长*宽*高" style="width: 80%;"></el-input><span>cm³</span>
                        </div>
                        <div style="margin-bottom:5px;display:flex;align-items: center;">
                            <el-input-number size="small" v-model="scope.row.weight" placeholder="重量" style="width: 80%;" controls-position="right"></el-input-number><span>kg</span>
                        </div>
                        <div style="text-align: right;">
                            <el-button size="small" type="primary" style="margin-right: 30px;" @click="handleSWClick(scope.row)">保存</el-button>
                        </div>
                        
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="100" prop="goods_value" :label="$t('packageOrder.name19')" show-overflow-tooltip></el-table-column>
<!--            <el-table-column min-width="100" prop="insure_fee" :label="$t('packageOrder.name11')" show-overflow-tooltip></el-table-column>-->
            <el-table-column min-width="100" prop="status_name" :label="$t('packageOrder.name18')"></el-table-column>
            <el-table-column :label="$t('btn.oprate')" fixed="right" width="160">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>{{$t('btn.oprate')}}</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <div style="display: flex;flex-direction: column;justify-content: start">
                        <el-button icon="Download" type="primary" text @click="handleDownloadMT(scope.row)" v-if="scope.row.logistics_num !=''&&scope.row.logistics_num.includes('AZT')">{{$t('packageOrder.op1')}}</el-button>
                        <el-button icon="Download" type="primary" text @click="handleDownloadExcel(scope.row)" v-if="scope.row.logistics_num !=''&&scope.row.logistics_num.includes('AZT')">{{$t('packageOrder.op2')}}</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {wuliuStoreuserorder,wuliuPackageorderDelete,platformsettingHycompany,wuliuPackagetype,wuliuStore,UsersStoreusers,wuliuStoreuserorderOperate,wuliuStoreuserorderStatistics,wuliuStoreuserorderExportexecl,wuliuStoreuserorderDownloadExcel} from '@/api/api'
    import { hasPermission } from "@/utils/util";
    import useTableHight from '@/mixins/useTableHight';
    import * as XLSX from 'xlsx';
    import FileSaver from "file-saver";
    import { useRouter,useRoute } from 'vue-router'
    import {useMutitabsStore} from "@/store/mutitabs";

    const mutitabsstore = useMutitabsStore()

    const route = useRoute()

    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect)
    let isFull = ref(false)
    let loadingPage = ref(false)
    let formInline = reactive({
        status:1,
        page: 1,
        limit: 10,
    })
    let orderstatics = ref({
        totalcount : 0 ,
        totalcount_today :0 ,
        totalincome : 0, 
        totalincome_today :0
    })
    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let pageparm = reactive({
        page: 1,
        limit: 10,
        total: 0
    })
    let tableData = ref([])
    let timers = ref([])
    let typeList = ref([])
    let statusList = ref([
        {id:3,name:'已完成'},
        {id:1,name:'待审核'},
        {id:2,name:'已拒绝'},
    ])
    let paymethodList = ref([
        {id:2,name:'微信支付'},
        {id:4,name:'钱包支付'},
    ])
    let qgStatusList = ref([
        {id:'抵达目的港',name:'抵达目的港'},
        {id:'上火车',name:'上火车'},
        {id:'完成本地缴税',name:'完成本地缴税'},
        {id:'完成清关',name:'完成清关'},
    ])

    function handleDownloadMT(row) {
        if(!row.logistics_num.includes("AZT")){
            ElMessage.warning("快递单号不符合AZT前缀要求")
            return
        }
        let params = {
            id:row.id,
            type:"dlmaitou",
        }
        wuliuStoreuserorderOperate(params).then(res => {
            if(res.code == 2000) {
                handleDownloadQrIMg(row.logistics_num,res.data)
                // ElMessage.success("下载成功")
            } else {
                ElMessage.warning(res.msg)
            }
        })
    }
    
    function handleDownloadExcel(row) {
        if(!row.logistics_num.includes("AZT")){
            ElMessage.warning("快递单号不符合AZT前缀要求")
            return
        }
        let params = {
            id:row.id,
            type:"dlinorder",
        }
        wuliuStoreuserorderDownloadExcel(params).then(res => {
            downloadExcelBlob(res,row.logistics_num)
        })
    }

    //excel文件流下载
    function downloadExcelBlob(res,orderno) {
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        //获取文件名
        const fileName = orderno+".xlsx"
        let href = window.URL.createObjectURL(blob); //下载链接
        let link = document.createElement("a")
        link.href = href
        link.download = fileName //下载后文件名
        document.body.appendChild(link);
        link.click(); //点击下载
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(href);  //释放blob对象
        ElMessage.success('下载成功')
    }

    //qrBase64是后台传回来的base64数据
    function handleDownloadQrIMg(orderno,qrBase64) {
      // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
      const imgUrl = qrBase64
      // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
      if (window.navigator.msSaveOrOpenBlob) {
        const bstr = atob(imgUrl.split(',')[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        const blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, orderno + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', orderno)
        a.click()
      }
      ElMessage.success("下载成功")
    }
    
    function handleStatusClick(tab,e) {
        formInline.status = tab.props.name
        search()
    }
    
    function caclSpec(spec) {
        if(!spec){
            return ""
        }
        let arr = spec.split("*")
        if(arr.length<3){
            return spec
        }
        return spec + "=" + (arr[0]*1)*(arr[1]*1)*(arr[2]*1) +"cm³"
    }
    function handleSaveLogistics(row) {
        let params = {
            id:row.id,
            type:"saveLogistics",
            logistics_num:row.logistics_num,
        }
        ElMessageBox.confirm('是否确认修改该订单快递单号吗?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function() {
            return wuliuStoreuserorderOperate(params).then(res => {
                if(res.code == 2000) {
                    ElMessage.success(res.msg)
                    getData()
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }).catch(()=>{})

    }
    function handleSWClick(row){
        let params = {
            id:row.id,
            type:"saveSW",
            specs:row.specs,
            weight:row.weight
        }
        wuliuStoreuserorderOperate(params).then(res => {
            if(res.code == 2000) {
                ElMessage.success(res.msg)
                getData()
            } else {
                ElMessage.warning(res.msg)
            }
        })
    }

    function isobject(item){
        if(typeof(item) == "object"){
            return true
        }else{
            return false
        }
    }
    function setFull(){
        isFull.value=!isFull.value
    }
    // 表格序列号
    function getIndex($index) {
        // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
        return (pageparm.page-1)*pageparm.limit + $index +1
    }

    let addModuleFlag = ref(null)

    function addModule() {
        addModuleFlag.value.addModuleFn(null,'新增','1')
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }
    /** 批量删除按钮操作 */
    function handleDelete(row) {
        ElMessageBox.confirm('是否确认删除选中的数据项?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function() {
            return wuliuPackageorderDelete({id:ids.value}).then(res=>{
                if(res.code == 2000) {
                    ElMessage.success(res.msg)
                    getData()
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }).catch(()=>{})
    }
    let logisticsModuleFlag = ref(null)
    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline = {
                page:1,
                limit: 10
            }
            pageparm={
                page: 1,
                limit: 10,
                total: 0
            }
            search()
        }else if(flag === 'delete'){
            ElMessageBox.confirm('确定删除该项吗？删除后无法恢复？',{
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(res=>{
                wuliuPackageorderDelete({id:row.id}).then(res=>{
                    if(res.code == 2000) {
                        ElMessage.success(res.msg)
                        getData()
                    } else {
                        ElMessage.warning(res.msg)
                    }
                })
            }).catch(()=>{})
        }
    }

    function callFather(parm) {
        formInline.page = parm.page
        formInline.limit = parm.limit
        getData()
    }
    function search() {
        formInline.page = 1
        formInline.limit = 10
        getOrderstatistics()
        getData()
    }
    //订单金额统计获取
    function getOrderstatistics(){
        wuliuStoreuserorderStatistics(formInline).then(res => {
            if(res.code ==2000) {
                orderstatics.value = res.data.data
            }
        })
    }
    function gettypeList(){
        wuliuPackagetype({page:1,limit:999}).then(res => {
            if(res.code ==2000) {
                typeList.value = res.data.data
            }
        })
    }
    let postStoreList = ref([])
    function getPostStoreList(){
        wuliuStore({page:1,limit:999}).then(res => {
            if(res.code ==2000) {
                postStoreList.value = res.data.data
            }
        })
    }
    let inStoreUserList = ref([])
    function getInStoreUserList(){
        UsersStoreusers({page:1,limit:999}).then(res => {
            if(res.code ==2000) {
                inStoreUserList.value = res.data.data
            }
        })
    }
    //获取列表
    async function getData(){
        loadingPage.value = true
        wuliuStoreuserorder(formInline).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                tableData.value = res.data.data
                pageparm.page = res.data.page;
                pageparm.limit = res.data.limit;
                pageparm.total = res.data.total;
            }
        })
    }
    function timeChange(val){
        if (val) {
            formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
            formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
        } else {
            formInline.beginAt = null
            formInline.endAt = null
        }
        search()
    }
    // 下载文件
    function downloadFileURL(url) {
        var iframe =document.createElement("iframe")
        iframe.style.display ="none";
        iframe.src = url;
        document.body.appendChild(iframe);
    }

    function exportData(){
        wuliuStoreuserorderExportexecl(formInline).then(res => {
             if(res.code ==2000) {
                 downloadFileURL(res.data.data)
             }else{
                  ElMessage.warning(res.msg)
             }
         })

    }
    /**
     * 从URL里下载文件
    */
    // 下载文件
    function downloadFile(url) {
        var iframe =document.createElement("iframe")
        iframe.style.display ="none";
        iframe.src = url;
        document.body.appendChild(iframe);
    }
    /** 导出全部数据(包含分页) */
    async function exportDataFrontend(){
        //分页情况先显示所有数据(在第一页)
        pageparm.page = 1; //当前页为第一页
        pageparm.limit = pageparm.total;//每页大小限制条数：为总条数（将表格数据全部渲染出来在第一页上）
        formInline.page = 1
        formInline.limit = pageparm.total
        await getData()
        setTimeout(()=>{
            exportExcel()
        },500)
    }
    let tableref = ref(null)
    function exportExcel(){
        nextTick(function () {
            var xlsxParam = {raw: true}; // 导出的内容只做解析，不进行格式转换(添加此行代码表格中的百分比就不会再导出的时候被转换成小数点)
            const $table = tableref.$el
            let tableDom = $table.cloneNode(true)
            if (tableDom.querySelector(".el-table__fixed-right")) {
                tableDom.removeChild(tableDom.querySelector(".el-table__fixed-right"))
            }
            let tableHeader = tableDom.querySelector('.el-table__header-wrapper');
            let tableBody = tableDom.querySelector('.el-table__body');
            tableHeader.childNodes[0].append(tableBody.childNodes[2]);
            let headerDom = tableHeader.childNodes[0].querySelectorAll('th');
            // 移除左侧checkbox的节点和操作列
            if (headerDom[0].querySelectorAll('.el-table-column--selection').length > 0) {
                headerDom[0].remove();
            }
            for (let key in headerDom) {
                if (headerDom[key].innerText === '' || headerDom[key].innerText === '操作' || headerDom[key].innerText === '用户头像' || headerDom[key].innerText === '序号') {
                    headerDom[key].remove();
                }
            }
            // 清理掉checkbox 和操作的button
            let tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
            for (let key = 0; key < tableList.length; key++) {
                if (tableList[key].querySelectorAll('.el-checkbox').length > 0 || tableList[key].querySelectorAll('.lyexportexclude').length > 0 || tableList[key].querySelectorAll('.el-button').length > 0 || tableList[key].querySelectorAll('.table-operate-btn').length > 0) {
                    tableList[key].remove();
                }
            }
            // 获取web的节点
            const wb = XLSX.utils.table_to_book(tableHeader, xlsxParam);//table_to_book放入的是table 的DOM 节点
            const wbout = XLSX.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array"
            });
            // 获取当前的时间戳，用来当文件名
            let time = new Date().getTime()
            try {
                FileSaver.saveAs(
                    new Blob([wbout], {type: "application/octet-stream"}),
                    `${time}.xlsx`
                ); //${time}是自己导出文件时的命名，随意
            } catch (e) {
                if (typeof console !== "undefined") console.log(e, wbout)
            }
            pageparm.limit = 10; //导出之后再恢复原样
            formInline.limit = 10;
            getData()
            return wbout
        })
    }

    onMounted(()=>{
         //不为空则赋值
        if(!Object.keys(route.query).length==0){
            if(route.query.userid){
                formInline.userid = route.query.userid
            }else if(route.query.storeuserid){
                formInline.storeuserid = route.query.storeuserid
            }
            mutitabsstore.switchtabNoRoute(route.name)
        }
        getData()
        getOrderstatistics()
        getPostStoreList()
        gettypeList()
        getInStoreUserList()
    })
</script>
<style lang="scss" scoped>
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 6px;
        align-items: center;
        li{
            margin-right: 30px;

        }
        button{
            height: 22px;
        }
    }
    .statusSelectTab{
        background: var(--l-searchtoolbar-bg);
        ::v-deep(.el-tabs__header){
            margin: 0 0 3px;
        }
    }
</style>