import { defineStore } from 'pinia'
import i18n from '@/locales'

export const useSiteThemeStore = defineStore('siteTheme', {
    state:() => {
        return {
            //控制主题:light正常模式、dark暗黑模式
            siteTheme:localStorage.getItem('siteTheme')?localStorage.getItem('siteTheme'):"light",
            language:localStorage.getItem("language") || 'zh-cn',
        }
    },
    getters:{
    },
    actions: {
        setSiteTheme(val) {
            this.siteTheme = val;
            localStorage.setItem('siteTheme',val);
        },
        setLanguage(val){
            this.language = val
            i18n.global.locale.value = val;
            localStorage.setItem('language', val);
        },
    },
})