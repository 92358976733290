<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" :before-close="handleClose">
            <el-table  border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
                <el-table-column min-width="160" prop="update_datetime" label="更新时间" show-overflow-tooltip></el-table-column>
                <el-table-column min-width="300" prop="content" label="更新内容" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" fixed="right" width="160">
                    <template #default="scope">
                        <el-popover placement="bottom" :visible="editVisible" :width="400" trigger="click">
                            <template #reference>
                                <span class="table-operate-btn" @click="handleEdit(scope.row,'update')">编辑</span>
                            </template>
                            <el-input
                            v-model="formData.content"
                            :autosize="{ minRows: 3, maxRows: 6 }"
                            type="textarea"
                            placeholder="请输入更新内容"
                            />
                            <div style="margin-top:20px;text-align:right;">
                                <el-button  size="small" @click="handleEdit('','editcancel')">取消</el-button>
                                <el-button  type="primary" size="small" @click="handleEdit(scope.row,'editadd')">保存编辑</el-button>
                            </div>
                            
                        </el-popover>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <el-popover placement="right" :visible="addVisible" :width="400" trigger="click">
                    <template #reference>
                        <el-button type="primary" @click="handleEdit('','create')">点击添加</el-button>
                    </template>
                    <el-input
                    v-model="formData.content"
                    :autosize="{ minRows: 3, maxRows: 6 }"
                    type="textarea"
                    placeholder="请输入更新内容"
                    />
                    <div style="margin-top:20px;text-align:right;">
                        <el-button  size="small" @click="handleEdit('','createaddcancel')">取消</el-button>
                        <el-button  type="primary" size="small" @click="handleEdit('','createadd')">保存添加</el-button>
                    </div>
                    
                </el-popover>
                <el-button @click="handleClose">关闭</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {wuliuLogistics,wuliuLogisticsAdd,wuliuLogisticsEdit,wuliuLogisticsDelete} from '@/api/api'
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "logisticsModule",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingPage:false,
                loadingTitle:"",
                addVisible:false,
                editVisible:false,
                formInline:{
                    counter_id:"",
                    page:1,
                    limit:999
                },
                formData:{
                    content:'',
                },
                tableData:[],
                rules:{
                    logistics_company: [
                        {required: true, message: '请填写物流公司',trigger: 'blur'}
                    ],
                    orderNo: [
                        {required: true, message: '请填写物流单号',trigger: 'blur'}
                    ],

                },
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.$emit('refreshData')
            },
            addModuleFn(item,flag) {
                this.dialogVisible=true
                this.loadingTitle = flag
                this.formInline.counter_id = item.id
                this.getData()
            },
            getData(){
                this.loadingPage = true
                wuliuLogistics(this.formInline).then(res=>{
                    this.loadingPage = false
                    if(res.code == 2000) {
                        this.tableData = res.data.data
                    }
                })
            },
            handleEdit(row,flag){
                if(flag=="delete"){
                    this.$confirm('确定删除该项吗？删除后无法恢复？',{
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(res=>{
                        wuliuLogisticsDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                this.$message.success(res.msg)
                                this.getData()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{})
                }else if(flag === 'create'){
                    this.addVisible = true
                    this.formData.content = ""
                }else if(flag === 'createadd'){
                    if(this.formData.content=== ""){
                        this.$message.warning("内容不能为空")
                        return
                    }
                    wuliuLogisticsAdd({counter:this.formInline.counter_id,content:this.formData.content}).then(res=>{
                        if(res.code == 2000) {
                            this.$message.success(res.msg)
                            this.addVisible = false
                            this.formData.content = ""
                            this.getData()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                    
                }else if(flag === 'createaddcancel'){
                    this.addVisible = false
                    this.formData.content = ""
                }else if(flag === 'update'){
                    this.editVisible = true
                    this.formData.content = row.content
                }else if(flag === 'editcancel'){
                    this.editVisible = false
                    this.formData.content = ""
                }else if(flag === 'editadd'){
                    if(this.formData.content=== ""){
                        this.$message.warning("内容不能为空")
                        return
                    }
                    wuliuLogisticsEdit({id:row.id,counter:this.formInline.counter_id,content:this.formData.content}).then(res=>{
                        if(res.code == 2000) {
                            this.$message.success(res.msg)
                            this.editVisible = false
                            this.formData.content = ""
                            this.getData()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                    
                }
                
            },
            deliverGoods() {
                if(this.formData.orderNo.length<1) {
                    this.$message.warning('请填写物流单号')
                    return
                }
                let vm = this

                mallGoodsSendoutgoods(this.formData).then(res=>{
                    if(res.code == 2000) {
                        vm.$message.success(res.msg)
                        this.handleClose()
                        this.$emit('refreshData')
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })

            }
        }
    }
</script>

