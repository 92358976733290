<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" :before-close="handleClose">
            <el-form :inline="false" :model="formData" ref="rulesForm" label-position="right" label-width="130px">
                <el-form-item label="转账人姓名：" prop="bank_transfer_name">
                    {{formData.bank_transfer_name}}
                </el-form-item>
                <el-form-item label="转账证明：">
                    <el-image :src="formData.bank_transfer_image" :preview-src-list="[formData.bank_transfer_image]" style="width: 60px;height:60px"></el-image>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    export default {
        name: "addModuleTransportMony",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('@/assets/img/avatar.jpg'),
                formData:{
                    bank_transfer_name:'',
                    bank_transfer_image:'',
                },
                rolelist:[],
                options:[],
            }
        },
        methods:{

            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.$emit('refreshData')
            },
            addModuleFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData = item
                }
            },
            submitData() {

            },
        }
    }
</script>

