<template>
    <div>
        <ly-crud ref="lycrud"  v-bind="crudConfig" ></ly-crud>
    </div>
</template>

<script lang="jsx">
    import {platformsettingHycompany,platformsettingHycompanyAdd,platformsettingHycompanyDelete,platformsettingHycompanyEdit} from '@/api/api'
    import LyCrud from "@/components/lycrud";
    import templateData from "@/components/dict/crudTemplateData"
    import { h,resolveComponent } from 'vue';
    export default {
        components: {LyCrud},
        data(){
            return{
                defaultImg:require('../../assets/img/avatar.jpg'),
                //crud配置
                crudConfig:{
                    //crud请求方法配置
                    crudRequest:{
                        add:platformsettingHycompanyAdd,
                        del:platformsettingHycompanyDelete,
                        edit:platformsettingHycompanyEdit,
                        search:platformsettingHycompany,
                    },
                    //搜索栏目配置
                    searchBar:{
                        showSearchBar:true,//显示搜索栏目
                        searchColums:[
                            {label:this.$t('hyCompany.name1'),type:'input',prop:'search',width:200,maxlength:60,placeholder:this.$t('hyCompany.name1')},
                        ]
                    },
                    //显示分页
                    showPagination:true,
                    //分页配置
                    pageparams: {
                        limit: 10,//每页显示的条数(默认每页显示10条)//非必填
                        //pageSizes:[10,20,30,40,50,200],//非必填
                        // layout:'total, sizes, prev, pager, next, jumper',//非必填
                    },
                    //crud按钮配置
                    rowHandle:{
                        width: 200,//操作列宽度
                        fixed:"right",//固定操作列在右侧
                        permission:{//增删改查按钮权限控制（是否显示）
                            add:this.hasPermission(this.$route.name,'Create'),//bool型 this.$options.name(this.$route.name)获取当前组件的name本例为userManageCrud
                            del:this.hasPermission(this.$route.name,'Delete'),
                            edit:this.hasPermission(this.$route.name,'Update'),
                            search:this.hasPermission(this.$route.name,'Search'),
                            detail:this.hasPermission(this.$route.name,'Retrieve'),
                        }
                    },
                    //crud弹窗属性
                    formOptions: {
                        width:'600px',//dialog弹窗宽度：类型：百分比或字符串
                        gutter: 20, // Layout布局栅格间隔
                    },
                    //crud表格属性
                    tableOptions:{
                        border:true,
                        showHeader:true,
                    },
                    showSelectable:true,//表格显示复选项框
                    //table表头列
                    tableColumns:[
                         // {label:'用户头像',type:'image',prop:'avatar',minWidth:'60',sortable: false,hidden:false,render:(row)=>{
                         //     let elImage = resolveComponent('el-image')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
                         //     return h(elImage,{
                         //          src:row.avatar ? row.avatar : this.defaultImg,
                         //          previewSrcList:[row.avatar ? row.avatar : this.defaultImg],//开启预览，原preview-src-list属性在h渲染方法中，-后字母大写代替即可：previewSrcList
                         //          previewTeleported:true,//插入至body元素上
                         //          style:"width: 30px;height: 30px",
                         //     },)
                         //  }
                         // },
                        {label:'ID',prop:'id',type:'input',minWidth:'100',sortable: false,hidden:true,
                            form:{
                                 //表单属性
                                 span:24,
                                 hidden:true,//编辑时隐藏，添加时去除
                             }
                        },
                         {label:this.$t('hyCompany.name1'),prop:'name',type:'input',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '公司名称必填项' }],
                                placeholder: this.$t('hyCompany.name1'),
                            }
                         },
                         {label:this.$t('hyCompany.name2'),prop:'sort',type:'number',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                placeholder: this.$t('hyCompany.name2'),
                            }
                         },
                         {label:this.$t('hyCompany.name3'),prop:'create_datetime',minWidth:'150',sortable: false,hidden:false},
                    ],
                },

            }
        },
        methods:{
            //自定义操作列按钮方法
            handleClick(row,flag){
                
            },
        },
    }
</script>

<style scoped>

</style>