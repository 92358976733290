<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item :label="$t('rechargeFina.name4')">
                    <el-select v-model="formInline.pay_method" :placeholder="$t('userManage.name5P')" clearable style="width: 130px" @change="search">
                        <el-option
                                v-for="item in paymethodList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('rechargeFina.name2')">
                    <el-input v-model.trim="formInline.nickname" maxlength="60"  clearable :placeholder="$t('rechargeFina.name2')" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('rechargeFina.name7')">
                    <el-select  v-model="formInline.status" :placeholder="$t('userManage.name5P')" clearable style="width: 120px" @change="search">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('rechargeFina.name5')">
                    <el-date-picker
                            style="width:280px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            :range-separator="$t('userManage.name7')"
                            :start-placeholder="$t('userManage.name6')"
                            :end-placeholder="$t('userManage.name8')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">{{$t('btn.search')}}</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">{{$t('btn.reset')}}</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="exportDataFrontend" type="primary">{{$t('btn.export')}}</el-button></el-form-item>
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>{{$t('rechargeFina.name9')}}：￥{{orderstatics.totalmoney}}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <el-table-column type="index" width="60" align="center" :label="$t('rechargeFina.name0')">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)" class="lyexportexclude"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="70" prop="avatar" :label="$t('rechargeFina.name1')">
                    <template #default="scope">
                        <img class="lyexportexclude" :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg">
                    </template>
            </el-table-column>
            <el-table-column min-width="110" prop="userinfo.nickname" :label="$t('rechargeFina.name2')"></el-table-column>
            <el-table-column min-width="120" prop="total_amount" :label="$t('rechargeFina.name3')"></el-table-column>
            <el-table-column min-width="130" prop="pay_method" :label="$t('rechargeFina.name4')"></el-table-column>
            <el-table-column min-width="150" prop="pay_time" :label="$t('rechargeFina.name5')"></el-table-column>
            <el-table-column min-width="180" prop="remark" :label="$t('rechargeFina.name6')"></el-table-column>
            <el-table-column min-width="80" prop="status_name" :label="$t('rechargeFina.name7')"></el-table-column>
            <el-table-column :label="$t('btn.oprate')" fixed="right" width="180" class="lyexportexclude">
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'detail')">{{$t('rechargeFina.name10')}}</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'audit')" v-show="hasPermission(this.$route.name,'Audit')" v-if="scope.row.status==1">{{$t('rechargeFina.name11')}}</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <audit-module ref="finacerechargeauditModuleFlag" @refreshData="getData"></audit-module>
        <add-module-transport-mony ref="finacerechargetransportModuleFlag"></add-module-transport-mony>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {wuliuFinancerecharge,wuliuFinancerechargeOrderstatistics} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    import { hasPermission } from "../../utils/util";
    import AuditModule from "./components/auditModule";
    import AddModuleTransportMony from "./components/addModuleTransportMony";
    import * as XLSX from 'xlsx';
    import FileSaver from "file-saver"
    import {useMutitabsStore} from "@/store/mutitabs";
    export default {
        mixins: [lyMixins],
        components:{
            AddModuleTransportMony,
            AuditModule,
            Pagination,
        },
        name:'financeStatisticsRecharge',
        setup(){
            const mutitabsstore = useMutitabsStore()
            return { mutitabsstore }
        },
        data() {
            return {
                isFull:false,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                orderstatics:{
                    totalmoney: 0,
                },
                defaultImg:require('../../assets/img/avatar.jpg'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                tableData:[],
                timers:[],
                statusList:[
                    {id:3,name:'已完成'},
                    {id:1,name:'待审核'},
                    {id:2,name:'已拒绝'},
                ],
                paymethodList:[
                    {id:2,name:'微信支付'},
                    {id:5,name:'银行卡转账'},
                    {id:6,name:'平台充值'},
                ],
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            addModule() {
                this.$refs.addModuleFlag.addModuleFn(null,'新增','1')
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=='detail') {
                    vm.$refs.finacerechargetransportModuleFlag.addModuleFn(row,'查看转账','1')
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.search()
                }
                else if(flag=='audit'){
                    this.$refs.finacerechargeauditModuleFlag.auditModuleFn(row)
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
                this.getOrderstatistics()
            },
            //订单金额统计获取
            getOrderstatistics(){
                wuliuFinancerechargeOrderstatistics(this.formInline).then(res => {
                     if(res.code ==2000) {
                         this.orderstatics = res.data.data
                     }
                 })
                  //   .finally(() => {
                  //  this.$nextTick(() => {
                  //     this.$refs.tableref.doLayout();
                  //   });
                  // });
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                wuliuFinancerecharge(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFile(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            /** 导出全部数据(包含分页) */
            async exportDataFrontend(){
                //分页情况先显示所有数据(在第一页)
                this.pageparm.page = 1; //当前页为第一页
                this.pageparm.limit = this.pageparm.total;//每页大小限制条数：为总条数（将表格数据全部渲染出来在第一页上）
                this.formInline.page = 1
                this.formInline.limit = this.pageparm.total
                await this.getData()
                setTimeout(()=>{
                    this.exportExcel()
                },500)
            },
            exportExcel(){
                this.$nextTick(function () {
                    var xlsxParam = {raw: true}; // 导出的内容只做解析，不进行格式转换(添加此行代码表格中的百分比就不会再导出的时候被转换成小数点)
                    const $table = this.$refs['tableref'].$el
                    let tableDom = $table.cloneNode(true)
                    if (tableDom.querySelector(".el-table__fixed-right")) {
                        tableDom.removeChild(tableDom.querySelector(".el-table__fixed-right"))
                    }
                    let tableHeader = tableDom.querySelector('.el-table__header-wrapper');
                    let tableBody = tableDom.querySelector('.el-table__body');
                    tableHeader.childNodes[0].append(tableBody.childNodes[2]);
                    let headerDom = tableHeader.childNodes[0].querySelectorAll('th');
                    // 移除左侧checkbox的节点和操作列
                    if (headerDom[0].querySelectorAll('.el-table-column--selection').length > 0) {
                        headerDom[0].remove();
                    }
                    for (let key in headerDom) {
                        if (headerDom[key].innerText === '' || headerDom[key].innerText === '操作' || headerDom[key].innerText === '用户头像' || headerDom[key].innerText === '序号') {
                            headerDom[key].remove();
                        }
                    }
                    // 清理掉checkbox 和操作的button
                    let tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
                    for (let key = 0; key < tableList.length; key++) {
                        if (tableList[key].querySelectorAll('.el-checkbox').length > 0 || tableList[key].querySelectorAll('.lyexportexclude').length > 0 || tableList[key].querySelectorAll('.el-button').length > 0 || tableList[key].querySelectorAll('.table-operate-btn').length > 0) {
                            tableList[key].remove();
                        }
                    }
                    // 获取web的节点
                    const wb = XLSX.utils.table_to_book(tableHeader, xlsxParam);//table_to_book放入的是table 的DOM 节点
                    const wbout = XLSX.write(wb, {
                        bookType: "xlsx",
                        bookSST: true,
                        type: "array"
                    });
                    // 获取当前的时间戳，用来当文件名
                    let time = new Date().getTime()
                    try {
                        FileSaver.saveAs(
                            new Blob([wbout], {type: "application/octet-stream"}),
                            `${time}.xlsx`
                        ); //${time}是自己导出文件时的命名，随意
                    } catch (e) {
                        if (typeof console !== "undefined") console.log(e, wbout)
                    }
                    this.pageparm.limit = 10; //导出之后再恢复原样
                    this.formInline.limit = 10;
                    this.getData()
                    return wbout
                })
            },
            exportData() {
                // var params = {
                //     type:3,
                //     page: 1,
                //     limit: 9999,
                // }
                // retrieveFinancestatisticsExport(params).then(res => {
                //      if(res.code ==2000) {
                //          this.downloadFile(res.data.data)
                //          //this.$message.warning(res.data.data)
                //      }
                //  })
            }
        },
        created() {
             //不为空则赋值
            if(!Object.keys(this.$route.query).length==0){
                this.formInline.userid = this.$route.query.userid
                this.mutitabsstore.switchtabNoRoute(this.$route.name)
            }
            this.getData()
            this.getOrderstatistics()
        }
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>