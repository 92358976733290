<template>
  <el-config-provider :locale="locale" size="default" :zIndex="3000">
      <router-view></router-view>
  </el-config-provider>
</template>
<script setup>
    import {ref, onMounted,watch,getCurrentInstance,computed} from 'vue'
    import {useSiteThemeStore} from "@/store/siteTheme";

    import { useI18n } from "vue-i18n";
    const i18n = useI18n();

    const siteThemeStore = useSiteThemeStore()

    onMounted(()=>{
        if (siteThemeStore.siteTheme === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    })

    const locale = computed(()=>{
        return i18n.messages.value[i18n.locale.value]? i18n.messages.value[i18n.locale.value].el :i18n.messages.value['zh-cn'].el
    })

    //防止页面刷新丢失主题
    watch(()=>siteThemeStore.siteTheme, (n,o) => {
        if (n === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    })
</script>
<style lang="scss">
  #app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    /*color: #2c3e50;*/
    height: 100%;
  }
</style>
