<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="560px" title="审核" :before-close="handleClose">
            <el-form :inline="true" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="130px">
                <el-form-item :label="$t('rechargeFina.name11')" prop="action">
                    <el-select v-model="formData.action" placeholder="请选择" clearable style="width: 300px">
                        <el-option
                                v-for="item in statusList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('rechargeFina.name6')" prop="remark">
                    <el-input type="textarea" v-model.trim="formData.remark" maxlength="200" show-word-limit clearable :placeholder="$t('rechargeFina.name6')" style="width:300px"></el-input>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button @click="handleClose" :loading="loadingSave">{{$t('btn.cancel')}}</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">{{$t('btn.confirm')}}</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import LyDialog from "@/components/dialog/dialog";
    import {wuliuFinancerechargePassDeny} from "@/api/api";
    export default {
        name: "auditModule",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                formData:{
                    id:'',
                    action:'',
                    remark:'',
                },
                rules:{
                    action: [
                        {required: true, message: '请选择审核结果',trigger: 'blur'}
                    ]
                },
                statusList:[
                    {id:'pass',name:this.$t('rechargeFina.nameop1')},
                    {id:'deny',name:this.$t('rechargeFina.nameop2')},
                ]
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    id:'',
                    action:'',
                    remark:'',
                },
                this.$emit('refreshData')
            },
            auditModuleFn(item) {
                this.dialogVisible=true
                this.formData.id = item.id
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        wuliuFinancerechargePassDeny(this.formData).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                })
            },

        }
    }
</script>

