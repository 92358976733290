<template>
    <div>
        <ly-crud ref="lycrud"  v-bind="crudConfig" >
            <template v-slot:customHandleHead="slotProps">
                <span class="table-operate-btn" @click="handleClick(slotProps.row,'record')">{{this.$t('storeManager.name6')}}</span>
            </template>
            <template v-slot:customHandle="slotProps">
                <span class="table-operate-btn" @click="handleClick(slotProps.row,'disable')" v-show="hasPermission(this.$route.name,'Disable')">
                    <span v-if="slotProps.row.is_active">{{this.$t('btn.disable')}}</span>
                    <span v-else>{{this.$t('btn.on')}}</span>
                </span>
            </template>
        </ly-crud>
    </div>
</template>

<script lang="jsx">
    import {UsersStoreusers,UsersStoreusersAdd,UsersStoreusersDelete,UsersStoreusersEdit,UsersStoreusersdisable,wuliuStore} from '@/api/api'
    import LyCrud from "@/components/lycrud";
    import templateData from "@/components/dict/crudTemplateData"
    import { h,resolveComponent } from 'vue';
    export default {
        components: {LyCrud},
        mounted(){
            this.crudConfig.tableColumns.forEach(item=>{
                if(item.prop === 'belong_store_id'){
                    item.form.options = this.storeList
                }
            })
        },
        data(){
            return{
                defaultImg:require('../../assets/img/avatar.jpg'),
                storeList:this.getStoreList(),
                //crud配置
                crudConfig:{
                    //crud请求方法配置
                    crudRequest:{
                        add:UsersStoreusersAdd,
                        del:UsersStoreusersDelete,
                        edit:UsersStoreusersEdit,
                        search:UsersStoreusers,
                    },
                    //搜索栏目配置
                    searchBar:{
                        showSearchBar:true,//显示搜索栏目
                        searchColums:[
                            {label:this.$t('storeManager.name2'),type:'input',prop:'name',width:200,maxlength:60,placeholder:this.$t('storeManager.name2')},
                            {label:this.$t('storeManager.name1'),type:'input',prop:'username',width:200,maxlength:60,placeholder:this.$t('storeManager.name1P')},
                            {label:this.$t('storeManager.name4'),type:'select',prop:'is_active',width:100,placeholder:this.$t('storeManager.name4P'),
                                options:[
                                    {value:1,label:this.$t('btn.nomal')},
                                    {value:0,label:this.$t('btn.disable')}
                                ]
                            }
                        ]
                    },
                    //显示分页
                    showPagination:true,
                    //分页配置
                    pageparams: {
                        limit: 10,//每页显示的条数(默认每页显示10条)//非必填
                        //pageSizes:[10,20,30,40,50,200],//非必填
                        // layout:'total, sizes, prev, pager, next, jumper',//非必填
                    },
                    //crud按钮配置
                    rowHandle:{
                        width: 370,//操作列宽度
                        fixed:"right",//固定操作列在右侧
                        permission:{//增删改查按钮权限控制（是否显示）
                            add:this.hasPermission(this.$route.name,'Create'),//bool型 this.$options.name(this.$route.name)获取当前组件的name本例为userManageCrud
                            del:this.hasPermission(this.$route.name,'Delete'),
                            edit:this.hasPermission(this.$route.name,'Update'),
                            search:this.hasPermission(this.$route.name,'Search'),
                            detail:this.hasPermission(this.$route.name,'Retrieve'),
                        }
                    },
                    //crud弹窗属性
                    formOptions: {
                        width:'45%',//dialog弹窗宽度：类型：百分比或字符串
                        gutter: 20, // Layout布局栅格间隔
                    },
                    //crud表格属性
                    tableOptions:{
                        border:true,
                        showHeader:true,
                    },
                    showSelectable:true,//表格显示复选项框
                    //table表头列
                    tableColumns:[
                         // {label:'用户头像',type:'image',prop:'avatar',minWidth:'60',sortable: false,hidden:false,render:(row)=>{
                         //     let elImage = resolveComponent('el-image')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
                         //     return h(elImage,{
                         //          src:row.avatar ? row.avatar : this.defaultImg,
                         //          previewSrcList:[row.avatar ? row.avatar : this.defaultImg],//开启预览，原preview-src-list属性在h渲染方法中，-后字母大写代替即可：previewSrcList
                         //          previewTeleported:true,//插入至body元素上
                         //          style:"width: 30px;height: 30px",
                         //     },)
                         //  }
                         // },
                        {label:'ID',prop:'id',type:'input',minWidth:'100',sortable: false,hidden:true,
                            form:{
                                 //表单属性
                                 span:24,
                                 hidden:true,//编辑时隐藏，添加时去除
                             }
                        },
                         {label:this.$t('storeManager.name1'),prop:'username',type:'input',minWidth:'110',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '账号必填项' }],
                                placeholder: this.$t('storeManager.name1P'),
                            }
                         },
                        {label:this.$t('login.PWD'),prop:'password',type:'input',minWidth:'110',sortable: false,hidden:true,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '密码必填项' }],
                                placeholder: this.$t('login.PWD'),
                                showPassword:true,
                            }
                         },
                         {label:this.$t('storeManager.name2'),prop:'name',type:'input',minWidth:'130',sortable: false,hidden:false,
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '姓名必填项' }],
                                placeholder: this.$t('storeManager.name2P'),
                            }
                         },
                         {label:this.$t('storeManager.name3'),prop:'belong_store_id',type:'select',minWidth:'180',sortable: false,hidden:false,
                            render:(row)=>{
                                //table显示样子
                                let namerow =  this.storeList.filter(item=>{
                                    return item.value === row.belong_store_id
                                })
                                return namerow.length>0?namerow[0].label:''
                            },
                            form:{
                                //表单属性
                                span:24,
                                rules: [{ required: true, message: '仓库必填项' }],
                                options:this.storeList,
                            },
                         },
                         {label:this.$t('storeManager.name4'),prop:'is_active',type:'radio',minWidth:'100',sortable: false,hidden:false,
                             render: (row) =>{
                                let elTag = resolveComponent('el-tag')//全局组件需要先resolveComponent解析该组件再render渲染，不然该标签会出现原样输出
                                if(!row.is_active){
                                    return h(elTag, {
                                      type:'warning'
                                    },()=>this.$t('btn.disable'));//用()=>函数方式返回文本值，避免出现console警告：默认槽位遇到非函数值
                                }else {
                                    return h(elTag, {
                                      type:'success'
                                    },()=>this.$t('btn.nomal'));
                                }
                              },
                             form:{
                                 //表单属性
                                 span:24,
                                 options:templateData.STATUS_BOOL,//radio选项属性值
                                 defaultValue:true,//新增时的默认值
                                 // valueChange(e){//值变化回调事件
                                 //     console.log(e,'---变化的值')
                                 // },
                             }
                         },
                         {label:this.$t('storeManager.name5'),prop:'create_datetime',minWidth:'150',sortable: false,hidden:false},
                    ],
                },

            }
        },
        methods:{
            //自定义操作列按钮方法
            handleClick(row,flag){
                let vm = this
                if(flag=='disable'){
                    UsersStoreusersdisable({id:row.id}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.$refs.lycrud.handleRefresh()//刷新表格数据
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }else if(flag==='record'){
                    vm.$router.push({name:'packageOrder',query:{storeuserid:row.id}})
                }
            },
            getStoreList(){
                let slist = []
                wuliuStore({page:1,limit:999}).then(res=>{
                    if(res.code == 2000) {
                        res.data.data.forEach(item=>{
                            slist.push({
                                value:item.id,
                                label:item.name
                            })
                        })
                        return slist
                    }
                })
                return slist
            },
        },
    }
</script>

<style scoped>

</style>