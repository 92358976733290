export default {
	login: {
		loginInTitle: 'Usuarios Iniciar sesión',
		loginAccount: 'Por favor Entrada Cuenta',
		loginPWD: 'Por favor Entrada Contraseña',
		code: 'Código de verificación',
		rememberMe: 'Recuerda contraseña',
		AccountError: 'Por favor. Entrada Cuenta',
		PWError: 'Por favor Entrada Contraseña',
		codeError: 'Por favor Entrada Código de verificación',
		login: 'Iniciar sesión',
		PWD:'Contraseña'
	},
	user:{
		wellcome:'hola',
		center:'centro personal',
		logout:'Salida Iniciar sesión',
	},
	btn:{
		del:'Eliminar',
		edit:'Editar',
		add:'Agregar',
		reset:'Reset',
		search:'Checar',
		detail:'Detalle',
		oprate:'Ajuste',
		fullscreen:'Pantalla completa',
		nomal:'Normal',
		disable:'Inutilizar',
		on:'Activar',
		choose:'please choose',
		orderrd:'Historial de Registro',
		rechargerd:'Historial de recarga',
		nodata:'No Data',
		cancel:'Cancel',
		confirm:'Confirm',
		save:'Guardar',
		saveConfig:'Save Config',
		export:'Exportar',
		keyWord:'Palabras clave',
		status:'Status',
		btnConfig:'BotonConfig',
		shi:'Si',
		fou:'No',
		qxbt:'permisoGestion',
	},
	adminManage:{
		name0:'Numero',
		name1:'Nombre del administrador',
		name2:'Cuenta de administrador',
		name3:'Permiso ?',
		name4:'Status',
		name5:'Fecha de Creacion',
		name6:'Iniciar sesión Contraseña',
		name7:'roles',
		name8:'departamento',
	},
	userManage:{
		name0:'Numero',
		name1:'ID de Usuario',
		name2:'Perfil',
		name3:'Nombre del usuario',
		name4:'Balance',
		name5:'Status',
		name3P:'Introduce el nombre de usuario',
		name1P:'Introduce el ID de usuario',
		name5P:'Elige',
		name6:'Fecha de inicio',
		name7:'a',
		name8:'Fecha Final',
	},
	storeManage:{
		name1:'Nombre de almacen',
		name1P:'Introduce el nombre de almacen',
		name2:'Consignatario',
		name3:'Contacto',
		name4:'Direccion',
		name5:'Codigo postal',
		name6:'Ordenar',
		name7:'Fecha de creacion',
	},
	storeManager:{
		name1:'Cuenta',
		name1P:'Introduce la cuenta',
		name2:'Nombre',
		name2P:'Introduce el nombre',
		name3:'Almacen',
		name4:'Status',
		name4P:'Elige',
		name5:'Fecha de creacion',
		name6:'in/out record',
	},
	carouselSettingsimg:{
		bname:'Gestion de la pagina de inicio',
		name0:'Numero',
		name1:'Foto',
		name2:'Asunto',
		name3:'Link',
		name4:'Ordenar',
		name5:'Status',
		name6:'Fecha de creacion',
	},
	messagNotice:{
		name0:'Numero',
		name1:'Titulo de anuncio',
		name2:'Imagen de portada',
		name3:'Contenido',
		name4:'Publicar?',
		name5:'Fecha de creacion',
		sname:'Titulo',
		snameP:'Titulo de mensaje',
	},
	helpCenter:{
		name1:'Titulo de la Pregunta',
		name1P:'Por favor ingrese un titulo de la pregunta',
		name2:'Contenido',
		name2P:'Por favor ingrese Contenido',
		name3:'Ordenar',
		name4:'Fecha de creacion',
	},
	userFeekback:{
		name0:'Numero',
		name1:'ID de usuario',
		name2:'Perfil',
		name3:'Nombre de usuario',
		name4:'Comentarios',
		name5:'Fecha de presentacion',
		name6:'Comentarios Imagen',
	},
	hyCompany:{
		name1:'nombre de la naviera',
		name2:'Ordenar',
		name3:'Fecha de creacion',
	},
	packageType:{
		name1:'Nombre de la categoria',
		name2:'Ordenar',
		name3:'Fecha de creacion',
	},
	packageOrder:{
		name0:'Numero',
		name1:'Informacion de usuario',
		name2:'ID de paquete/Numero de rastreo',
		name3:'Informacion del articulo',
		name4:'Fecha de presentacion/Almacenamiento',
		name5:'Foto de almacenamiento',
		name6:'Operador',
		name7:'Direccion del receptor',
		name8:'Contacto de emergencia',
		name9:'CBM/Peso',
		name10:'Flete por pagar',
		name11:'Seguro',
		name12:'Total',
		name13:'Metodo de pago',
		name14:'Fecha de pago',
		name15:'Estado del despacho de aduana',
		name16:'Fecha de termina',
		name17:'Operacion completo',
		name18:'Estado del paquete',
		name19:'Precio total Mercancías',
		name20:'Nombre del cliente',
		name21:'Teléfono móvil del cliente',
		name22:'Pagado',
		name23:'Numero de rastreo',
		name24:'Nota',
		name25:'Número de contenedor',
		sh1:'Nombre',
		sh2:'Numero de rastreo',
		sh3:'ID de paquete',
		sh4:'Nombre de Articulo',
		sh5:'Tipo de articulo',
		sh6:'Almacen',
		sh7:'Fecha de presentacion',
		sh8:'Número de artículos',
		sh9:'Cálculo automático',
		tt1:'Volumen de paquetes de hoy',
		tt2:'Volumen total',
		tt3:'Ingreso de hoy',
		tt4:'Ingreso total',
		status1:'No almacenado',
		status2:'Se ha almacenado',
		op1:'Marca de envío',
		op2:'Almacén Pedidos'
	},
	counterManage:{
		name0:'Numero',
		name1:'Numero de contenedor',
		name2:'BL',
		name3:'Numero de reserva',
		name4:'Naviera',
		name6:'Contacto',
		name7:'Informacion del contacto',
		name8:'Fecha de presentacion',
		name9:'Actualizacion de logistica',
		name10:'Logística',
	},
	orderFina:{
		name0:'Numero',
		name1:'Perfil',
		name2:'Nombre',
		name3:'ID de paquete',
		name4:'Monto del pago',
		name5:'Metodo de pago',
		name6:'Fecha de pago',
		name7:'ID de Usuario',
		name8:'Importe total de los ingresos',
	},
	rechargeFina:{
		name0:'Numero',
		name1:'Perfil',
		name2:'Nombre',
		name3:'Monto de pago',
		name4:'Metodo de pago',
		name5:'Fecha de pago',
		name6:'Comentario',
		name7:'Status',
		name8:'ID de Usuario',
		name9:'Importe total de los ingresos',
		name10:'Ver transferencias',
		name11:'Revisión',
		nameop1:'Aprobado',
		nameop2:'Denegada',
	},
	pCenter:{
		chsName1:'Ajuste de usuario',
		chsName2:'Ajuste de Contraseña',
		chsName1NK:'Nombre',
		chsName1PH:'Telefono',
		chsName1EM:'Correo',
		chsName1Sex:'Sexo',
		chsName1Man:'Hombre',
		chsName1Girl:'Mujer',
		chsName1None:'N/A',
		chsName1Update:'Actualización',
		chsName2Pwd:'Contraseña',
		chsName2NewPwd:'Nuevo contraseña',
		chsName2CPwd:'Confirmar contraseña',
		chsName2Submit:'Entregar',
		chsName2Reset:'Reset',
	},
	deptManage:{
		name0:'Numero',
		name1:'Nombre de departamento',
		name2:'Encargado',
		name3:'Contacto',
		name4:'Correo',
		name5:'Ordenar',
		name6:'Fecha de creacion',
		name7:'padres Departamento',
	},
	menuManage:{
		name0:'Numero',
		name1:'Nombre de menu',
		name2:'Icono',
		name3:'Ordenar',
		name4:'Direccion de enrutamiento',
		name5:'Permisos',
		name6:'Lado visible',
		name7:'Status',
		name8:'Menú padre',
	},
	roleManage:{
		name0:'Numero',
		name1:'Nombre de roles',
		name2:'Permiso',
		name3:'Es administrador ?',
		name4:'Status',
		name5:'Ordenar',
	},
	authorityManage:{
		name0:'Seleccion Actua',
		name1:'Autorizacion de menu',
		name2:'Seleccione un usuario primero',
		name3:'Autorizacion de datos',
	},
	logManage:{
		name0:'Numero',
		name1:'Modulo de solicitud',
		name2:'Direccion de solicitud',
		name3:'Metodo de solicitud',
		name4:'Direccion de IP',
		name5:'Navegador',
		name6:'Solicitud de datos',
		name7:'Codigo de respuesta',
		name8:'Mensaje devuelta',
		name9:'Operador',
		name10:'Fecha de creacion',
		name11:'Eliminar todo',
	},
}