<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item :label="$t('counterManage.name1')">
                    <el-input v-model.trim="formInline.counter_num" maxlength="60"  clearable :placeholder="$t('counterManage.name1')" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('counterManage.name2')">
                    <el-input v-model.trim="formInline.t_order_num" maxlength="60"  clearable :placeholder="$t('counterManage.name2')" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('counterManage.name3')">
                    <el-input v-model.trim="formInline.d_order_num" maxlength="60"  clearable :placeholder="$t('counterManage.name3')" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('counterManage.name4')">
                    <el-select v-model="formInline.haiyun_company" :placeholder="$t('userManage.name5P')" clearable style="width: 130px" @change="search" filterable allow-create>
                        <el-option
                                v-for="item in companyList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('counterManage.name6')">
                    <el-input v-model.trim="formInline.contacts" maxlength="60"  clearable :placeholder="$t('counterManage.name6')" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('counterManage.name7')">
                    <el-input  v-model.trim="formInline.mobile" maxlength="60"  clearable :placeholder="$t('counterManage.name7')" @change="search" style="width:150px"></el-input>
                </el-form-item>
                <!-- <el-form-item label="付款时间：">
                    <el-date-picker
                            style="width:280px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label="" v-show="hasPermission(route.name,'Search')"><el-button  @click="search" type="primary" icon="Search">{{$t('btn.search')}}</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">{{$t('btn.reset')}}</el-button></el-form-item>
                <!-- <el-form-item label=""><el-button  @click="exportDataFrontend" type="primary">导出</el-button></el-form-item> -->
                <el-form-item label="" v-show="hasPermission(route.name,'Delete')"><el-button  @click="handleDelete" type="danger" :disabled="multiple">{{$t('btn.del')}}</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="index" width="60" align="center" :label="$t('counterManage.name0')">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)" class="lyexportexclude"></span>
                </template>
            </el-table-column>
            <el-table-column min-width="130" prop="counter_num" :label="$t('counterManage.name1')" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="130" prop="t_order_num" :label="$t('counterManage.name2')" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="130" prop="d_order_num" :label="$t('counterManage.name3')" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="150" prop="haiyun_companyname" :label="$t('counterManage.name4')" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="150" prop="contacts" :label="$t('counterManage.name6')" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="150" prop="mobile" :label="$t('counterManage.name7')" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="150" prop="create_datetime" :label="$t('counterManage.name8')" show-overflow-tooltip></el-table-column>
            <el-table-column min-width="200" prop="newlogistics" :label="$t('counterManage.name9')" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('btn.oprate')" fixed="right" width="160">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>{{$t('btn.oprate')}}</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon ><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'wuliuupdate')">{{$t('counterManage.name10')}}</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(route.name,'Delete')">{{$t('btn.del')}}</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <logisticsModule ref="logisticsModuleFlag" @refreshData="getData"></logisticsModule>
    </div>
</template>
<script setup>
    import {ref,reactive,onMounted,onBeforeUnmount,nextTick} from 'vue'
    import { ElMessage,ElMessageBox } from 'element-plus';
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {wuliuCounter,wuliuCounterDelete,platformsettingHycompany} from '@/api/api'
    import { hasPermission } from "@/utils/util";
    import useTableHight from '@/mixins/useTableHight';
    import * as XLSX from 'xlsx';
    import FileSaver from "file-saver";
    import logisticsModule from "./components/logisticsModule"
    import { useRouter,useRoute } from 'vue-router'

    const route = useRoute()

    let orderStatic = ref(null)
    let tableSelect = ref(null)
    let tableHeight = useTableHight(orderStatic,tableSelect)
    let isFull = ref(false)
    let loadingPage = ref(false)
    let formInline = reactive({
        page: 1,
        limit: 10,
    })
    let orderstatics = reactive({
        totalmoney: 0,
    })
    let defaultImg = ref(require('../../assets/img/avatar.jpg'))
    let pageparm = reactive({
        page: 1,
        limit: 10,
        total: 0
    })
    let tableData = ref([])
    let timers = ref([])
    let statusList = ref([
        {id:3,name:'已完成'},
        {id:1,name:'待审核'},
        {id:2,name:'已拒绝'},
    ])
    let paymethodList = ref([
        {id:2,name:'微信支付'},
        {id:5,name:'银行卡转账'},
        {id:6,name:'平台充值'},
    ])

    function setFull(){
        isFull.value=!isFull.value
    }
    // 表格序列号
    function getIndex($index) {
        // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
        return (pageparm.page-1)*pageparm.limit + $index +1
    }

    let addModuleFlag = ref(null)

    function addModule() {
        addModuleFlag.value.addModuleFn(null,'新增','1')
    }

    // 选项框选中数组
    let ids = ref([])
    // 选项框非单个禁用
    let single = ref(true)
    // 非多个禁用
    let multiple = ref(true)
    //多选项框被选中数据
    function handleSelectionChange(selection) {
        ids.value = selection.map(item => item.id);
        single.value = selection.length !== 1;
        multiple.value = !selection.length;
    }
    /** 批量删除按钮操作 */
    function handleDelete(row) {
        ElMessageBox.confirm('是否确认删除选中的数据项?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function() {
            return wuliuCounterDelete({id:ids.value}).then(res=>{
                if(res.code == 2000) {
                    ElMessage.success(res.msg)
                    getData()
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }).catch(()=>{})
    }
    let logisticsModuleFlag = ref(null)
    function handleEdit(row,flag) {
        if(flag=="reset"){
            formInline = {
                page:1,
                limit: 10
            }
            pageparm={
                page: 1,
                limit: 10,
                total: 0
            }
            search()
        }else if(flag === 'wuliuupdate'){
            logisticsModuleFlag.value.addModuleFn(row,'物流更新')
        }else if(flag === 'delete'){
            ElMessageBox.confirm('确定删除该项吗？删除后无法恢复？',{
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(res=>{
                wuliuCounterDelete({id:row.id}).then(res=>{
                    if(res.code == 2000) {
                        ElMessage.success(res.msg)
                        getData()
                    } else {
                        ElMessage.warning(res.msg)
                    }
                })
            }).catch(()=>{})
        }
    }

    function callFather(parm) {
        formInline.page = parm.page
        formInline.limit = parm.limit
        getData()
    }
    function search() {
        formInline.page = 1
        formInline.limit = 10
        getData()
        // getOrderstatistics()
    }
    //订单金额统计获取
    function getOrderstatistics(){
        // wuliuFinancepackageorderrderstatistics(formInline).then(res => {
        //     if(res.code ==2000) {
        //         orderstatics = res.data.data
        //     }
        // })
    }
    let companyList = ref([])
    function getCompany(){
        platformsettingHycompany({page:1,limit:999}).then(res => {
            if(res.code ==2000) {
                companyList.value = res.data.data
            }
        })
    }
    //获取列表
    async function getData(){
        loadingPage.value = true
        wuliuCounter(formInline).then(res => {
            loadingPage.value = false
            if(res.code ==2000) {
                tableData.value = res.data.data
                pageparm.page = res.data.page;
                pageparm.limit = res.data.limit;
                pageparm.total = res.data.total;
            }
        })
    }
    function timeChange(val){
        if (val) {
            formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
            formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
        } else {
            formInline.beginAt = null
            formInline.endAt = null
        }
        search()
    }
    /**
     * 从URL里下载文件
    */
    // 下载文件
    function downloadFile(url) {
        var iframe =document.createElement("iframe")
        iframe.style.display ="none";
        iframe.src = url;
        document.body.appendChild(iframe);
    }
    /** 导出全部数据(包含分页) */
    async function exportDataFrontend(){
        //分页情况先显示所有数据(在第一页)
        pageparm.page = 1; //当前页为第一页
        pageparm.limit = pageparm.total;//每页大小限制条数：为总条数（将表格数据全部渲染出来在第一页上）
        formInline.page = 1
        formInline.limit = pageparm.total
        await getData()
        setTimeout(()=>{
            exportExcel()
        },500)
    }
    let tableref = ref(null)
    function exportExcel(){
        nextTick(function () {
            var xlsxParam = {raw: true}; // 导出的内容只做解析，不进行格式转换(添加此行代码表格中的百分比就不会再导出的时候被转换成小数点)
            const $table = tableref.$el
            let tableDom = $table.cloneNode(true)
            if (tableDom.querySelector(".el-table__fixed-right")) {
                tableDom.removeChild(tableDom.querySelector(".el-table__fixed-right"))
            }
            let tableHeader = tableDom.querySelector('.el-table__header-wrapper');
            let tableBody = tableDom.querySelector('.el-table__body');
            tableHeader.childNodes[0].append(tableBody.childNodes[2]);
            let headerDom = tableHeader.childNodes[0].querySelectorAll('th');
            // 移除左侧checkbox的节点和操作列
            if (headerDom[0].querySelectorAll('.el-table-column--selection').length > 0) {
                headerDom[0].remove();
            }
            for (let key in headerDom) {
                if (headerDom[key].innerText === '' || headerDom[key].innerText === '操作' || headerDom[key].innerText === '用户头像' || headerDom[key].innerText === '序号') {
                    headerDom[key].remove();
                }
            }
            // 清理掉checkbox 和操作的button
            let tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
            for (let key = 0; key < tableList.length; key++) {
                if (tableList[key].querySelectorAll('.el-checkbox').length > 0 || tableList[key].querySelectorAll('.lyexportexclude').length > 0 || tableList[key].querySelectorAll('.el-button').length > 0 || tableList[key].querySelectorAll('.table-operate-btn').length > 0) {
                    tableList[key].remove();
                }
            }
            // 获取web的节点
            const wb = XLSX.utils.table_to_book(tableHeader, xlsxParam);//table_to_book放入的是table 的DOM 节点
            const wbout = XLSX.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array"
            });
            // 获取当前的时间戳，用来当文件名
            let time = new Date().getTime()
            try {
                FileSaver.saveAs(
                    new Blob([wbout], {type: "application/octet-stream"}),
                    `${time}.xlsx`
                ); //${time}是自己导出文件时的命名，随意
            } catch (e) {
                if (typeof console !== "undefined") console.log(e, wbout)
            }
            pageparm.limit = 10; //导出之后再恢复原样
            formInline.limit = 10;
            getData()
            return wbout
        })
    }

    onMounted(()=>{
        getData()
        getCompany()
    })
</script>
<style lang="scss" scoped>
</style>