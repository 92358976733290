export default {
	login: {
		loginInTitle: '用户登录',
		loginAccount: '请输入账号',
		loginPWD: '请输入密码',
		code: '验证码',
		rememberMe: '记住密码',
		AccountError: '请输入用户名',
		PWError: '请输入密码',
		codeError: '请输入验证码',
		login: '登录',
		PWD:'密码'
	},
	user:{
		wellcome:'你好',
		center:'个人中心',
		logout:'退出登录',
	},
	btn:{
		del:'删除',
		edit:'编辑',
		add:'新增',
		reset:'重置',
		search:'查询',
		detail:'详情',
		oprate:'操作',
		fullscreen:'全屏',
		nomal:'正常',
		disable:'禁用',
		on:'启用',
		orderrd:'订单记录',
		rechargerd:'充值记录',
		nodata:'暂无数据',
		cancel:'取消',
		confirm:'确定',
		save:'保存',
		saveConfig:'保存配置',
		export:'导出',
		keyWord:'关键词',
		status:'状态',
		btnConfig:'按钮配置',
		shi:'是',
		fou:'否',
		qxbt:'权限管理',
	},
	adminManage:{
		name0:'序号',
		name1:'管理员名称',
		name2:'管理员账号',
		name3:'权限字符',
		name4:'状态',
		name5:'创建时间',
		name6:'登录密码',
		name7:'角色',
		name8:'部门',
	},
	userManage:{
		name0:'序号',
		name1:'会员ID',
		name2:'用户头像',
		name3:'用户名称',
		name4:'钱包余额',
		name5:'状态',
		name3P:'请输入用户名称',
		name1P:'请输入会员ID',
		name5P:'请选择',
		name6:'开始日期',
		name7:'至',
		name8:'结束日期',
	},
	storeManage:{
		name1:'仓库名称',
		name1P:'请输入仓库名称',
		name2:'收件人',
		name3:'联系方式',
		name4:'地址',
		name5:'邮编',
		name6:'排序',
		name7:'创建时间',
	},
	storeManager:{
		name1:'账号',
		name1P:'请输入账号',
		name2:'姓名',
		name2P:'请输入姓名',
		name3:'所属仓库',
		name4:'状态',
		name4P:'请选择',
		name5:'创建时间',
		name6:'出/入库记录',
	},
	carouselSettingsimg:{
		bname:'首页轮播图管理',
		name0:'序号',
		name1:'图片',
		name2:'标题',
		name3:'链接',
		name4:'排序',
		name5:'状态',
		name6:'创建时间',
	},
	messagNotice:{
		name0:'序号',
		name1:'公告标题',
		name2:'封面图',
		name3:'内容',
		name4:'是否发布',
		name5:'创建时间',
		sname:'标题',
		snameP:'消息标题',
	},
	helpCenter:{
		name1:'问题标题',
		name1P:'请输入问题标题',
		name2:'内容',
		name2P:'请输入内容',
		name3:'排序',
		name4:'创建时间',
	},
	userFeekback:{
		name0:'序号',
		name1:'会员ID',
		name2:'用户头像',
		name3:'用户昵称',
		name4:'反馈内容',
		name5:'提交时间',
		name6:'反馈图片',
	},
	hyCompany:{
		name1:'公司名称',
		name2:'排序',
		name3:'创建时间',
	},
	packageType:{
		name1:'分类名称',
		name2:'排序',
		name3:'创建时间',
	},
	packageOrder:{
		name0:'序号',
		name1:'用户信息',
		name2:'包裹ID/快递单号',
		name3:'物品信息',
		name4:'提交时间/入库',
		name5:'入库拍照',
		name6:'入库操作员',
		name7:'收件人地址',
		name8:'紧急联系人',
		name9:'体积/重量',
		name10:'应付邮费',
		name11:'保险费',
		name12:'费用总计',
		name13:'支付方式',
		name14:'付款时间',
		name15:'清关状态',
		name16:'完成时间',
		name17:'完成操作',
		name18:'包裹状态',
		name19:'货物总值',
		name20:'客户姓名',
		name21:'客户手机',
		name22:'已付金额',
		name23:'快递单号',
		name24:'备注',
		name25:'货柜号',
		sh1:'昵称',
		sh2:'快递单号',
		sh3:'包裹ID',
		sh4:'物品名称',
		sh5:'物品类型',
		sh6:'邮寄仓库',
		sh7:'提交时间',
		sh8:'物品数量',
		sh9:'自动计算',
		tt1:'今日包裹量',
		tt2:'累计包裹量',
		tt3:'今日收益',
		tt4:'累计收益',
		status1:'未入库',
		status2:'已入库',
		op1:'唛头',
		op2:'进仓单',
	},
	counterManage:{
		name0:'序号',
		name1:'货柜号码',
		name2:'提单号码',
		name3:'订舱号码',
		name4:'海运公司',
		name6:'联系人',
		name7:'联系方式',
		name8:'提交时间',
		name9:'最新物流',
		name10:'物流更新',
	},
	orderFina:{
		name0:'序号',
		name1:'用户头像',
		name2:'昵称',
		name3:'包裹ID',
		name4:'支付金额',
		name5:'支付方式',
		name6:'付款时间',
		name7:'会员ID',
		name8:'收益金额',
	},
	rechargeFina:{
		name0:'序号',
		name1:'用户头像',
		name2:'昵称',
		name3:'充值金额',
		name4:'支付方式',
		name5:'付款时间',
		name6:'备注',
		name7:'状态',
		name8:'会员ID',
		name9:'收益总金额',
		name10:'查看转账',
		name11:'审核',
		nameop1:'通过',
		nameop2:'拒绝',
	},
	pCenter:{
		chsName1:'用户设置',
		chsName2:'密码设置',
		chsName1NK:'昵称',
		chsName1PH:'电话号码',
		chsName1EM:'邮箱',
		chsName1Sex:'性别',
		chsName1Man:'男',
		chsName1Girl:'女',
		chsName1None:'未知',
		chsName1Update:'更新',
		chsName2Pwd:'原密码',
		chsName2NewPwd:'新密码',
		chsName2CPwd:'确认密码',
		chsName2Submit:'提交',
		chsName2Reset:'重置',
	},
	deptManage:{
		name0:'序号',
		name1:'部门名称',
		name2:'负责人',
		name3:'联系电话',
		name4:'邮箱',
		name5:'排序',
		name6:'创建时间',
		name7:'父级部门',
	},
	menuManage:{
		name0:'序号',
		name1:'菜单名称',
		name2:'图标',
		name3:'排序',
		name4:'路由地址',
		name5:'权限',
		name6:'侧边可见',
		name7:'状态',
		name8:'父级菜单',
	},
	roleManage:{
		name0:'序号',
		name1:'角色名称',
		name2:'权限字符',
		name3:'是否管理员',
		name4:'状态',
		name5:'排序',
	},
	authorityManage:{
		name0:'当前选择',
		name1:'菜单授权',
		name2:'请先选择角色',
		name3:'数据授权',
	},
	logManage:{
		name0:'序号',
		name1:'请求模块',
		name2:'请求地址',
		name3:'请求方法',
		name4:'IP地址',
		name5:'请求浏览器',
		name6:'请求数据',
		name7:'响应码',
		name8:'返回信息',
		name9:'操作人',
		name10:'创建时间',
		name11:'全部清除',
	},
}